<template>
  <div>
    <!-- <Overlay :overlay="overlay" /> -->
    <v-card class="elevation-0">
      <v-card-text>
  
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { GetAllDashboardParameter } from "@/mixins/GetAllDashboardParameter.js";
import { GetAllInitialAlarms } from "@/mixins/GetAllInitialAlarms.js";
export default {
  mixins: [GetAllDashboardParameter, GetAllInitialAlarms],
  components: {},
  data: () => ({
    GetAllInitialAlarmsList: [],
    operationalHeader: [
      { text: "Parameter", value: "tag_description" },
      { text: "Actual", value: "actual_value" },
      { text: "Comm.", value: "commissioning_value" },
    ],
    alarmHeader: [{ text: "Name", value: "tag_description" }],
    Height: 0,
    plant_types: [],
    GetAllDashboardParameterList: [],
    summaryHeaders: [
      { text: "", value: "tag_description" },
      { text: "", value: "alarm_color" },
    ],
    ConnectionDashboard: null,
    stepObject: {},
    dailyLogs: {},
    setIntervalString: null,
    connected: false,
    screenHeight: 0,
    OPHeight: 0,
    heart_beat_color: "",
    recovery_percentage: {},
    count: 0,
    reloadcount: 0,
    renderOPData: true,
    renderData: true,
    startstop: null,
  }),
  watch: {
    GetAllDashboardParameterList(val) {
      this.plant_types = [];
      if (val && val.length != 0) {
        this.heart_beat_color = val.filter((item) => item.type == "HEARTBEAT")[0].color;
        this.$emit("heartBeatColor", this.heart_beat_color);
        let plant_type = [...new Set(val.filter((item) => item.tag_type == "DI").map((plant) => plant.plant_name))]
          .sort()
          .reverse();
        let diff_plant_types = [];
        plant_type.map((plant) => {
          if (!isNaN(Number(plant.slice(-1)))) {
            if (diff_plant_types.indexOf(plant.slice(0, plant.length - 1)) == -1) {
              diff_plant_types.push(plant.slice(0, plant.length - 1));
            }
          } else {
            if (diff_plant_types.indexOf(plant) == -1) {
              diff_plant_types.push(plant);
            }
          }
        });
        this.plant_types = diff_plant_types
          .map((plant) => plant_type.filter((plant_name) => plant_name.includes(plant)).sort())
          .flat(Infinity);
        this.recovery_percentage = {};
        for (let i = 0; i < this.plant_types.length; i++) {
          this.recovery_percentage[this.plant_types[i]] = "";
          for (let j = 0; j < val.filter((item) => item.type == "RECOVERY").length; j++) {
            if (this.plant_types[i] == val.filter((item) => item.type == "RECOVERY")[j].plant_name) {
              this.recovery_percentage[this.plant_types[i]] = val.filter((item) => item.type == "RECOVERY")[
                j
              ].actual_value;
            }
          }
        }

        this.stepObject = {};
        for (let i = 0; i < val.filter((item) => item.type == "STEP").length; i++) {
          this.stepObject[val.filter((item) => item.type == "STEP")[i].plant_name] = val.filter(
            (item) => item.type == "STEP"
          )[i];
        }
        let dailyLogsObject = val.filter((item) => item.type == "DAILY_LOGS")[0];
        if (dailyLogsObject["dmf_manual_backwash_value"] != undefined) {
          this.dailyLogs["dmf_manual_backwash_value"] = dailyLogsObject["dmf_manual_backwash_value"];
        }
        if (dailyLogsObject["uf_plant_inlet"] != undefined) {
          this.dailyLogs["uf_plant_inlet"] = dailyLogsObject["uf_plant_inlet"];
        }
        for (let i = 0; i < this.plant_types.length; i++) {
          if (dailyLogsObject[this.plant_types[i]] != undefined) {
            this.dailyLogs[this.plant_types[i]] = dailyLogsObject[this.plant_types[i]];
            this.dailyLogs[`${this.plant_types[i].toLowerCase()}_water_produced`] =
              dailyLogsObject[`${this.plant_types[i].toLowerCase()}_water_produced`] != undefined
                ? dailyLogsObject[`${this.plant_types[i].toLowerCase()}_water_produced`]
                : 0;
            this.dailyLogs[`${this.plant_types[i].toLowerCase()}_total_hours_of_operation`] =
              dailyLogsObject[`${this.plant_types[i].toLowerCase()}_total_hours_of_operation`] != undefined
                ? dailyLogsObject[`${this.plant_types[i].toLowerCase()}_total_hours_of_operation`]
                : 0;
          }
        }
      }
    },
  },
  created() {
    if (this.ConnectionDashboard != null) {
      this.ConnectionDashboard.close();
    }
    this.WebSocketConection();
  },
  beforeDestroy() {
    this.ConnectionDashboard.close();
    clearInterval(this.startstop);
  },
  mounted() {
    this.setIntervalMethod();
    this.GetAllInitialAlarmsListMethod(this.$store.getters.get_asset_object);
    this.GetAllDashboardParameterMethod(this.$store.getters.get_asset_object.asset_id);
    this.Height = window.innerHeight - 155;
    this.OPHeight = window.innerHeight;
    this.screenHeight = screen.height;
  },
  methods: {
    setReconnectMethod() {
      var self = this;
      self.reconnect = setInterval(() => {
        self.ConnectionDashboard.close();
        self.WebSocketConection();
      }, 10000);
    },
    sortMethod(array) {
      return array.sort((a, b) => a.sort_count - b.sort_count);
    },
    setIntervalMethod() {
      var self = this;
      self.startstop = setInterval(function () {
        if (self.dailyLogs && Object.keys(self.dailyLogs).length != 0) {
          for (let i = 0; i < self.plant_types.length; i++) {
            for (let key in self.dailyLogs) {
              if (self.plant_types[i] == key) {
                if (self.dailyLogs[self.plant_types[i]].length != 0) {
                  if (
                    self.dailyLogs[self.plant_types[i]][self.dailyLogs[self.plant_types[i]].length - 1].stop ==
                    undefined
                  ) {
                    self.dailyLogs[`${key.toLowerCase()}_total_hours_of_operation`] += 1;
                    self.$forceUpdate();
                  }
                }
              }
            }
          }
        }
      }, 1000);
    },
    convertTimeToHRMinSec(time) {
      let value = time * 1000;
      this.hour = Math.floor(value / 1000 / 60 / 60);
      value -= this.hour * 1000 * 60 * 60;
      this.minute = Math.floor(value / 1000 / 60);
      value -= this.minute * 1000 * 60;
      this.seconds = Math.floor(value / 1000);
      return this.addZero(this.hour) + ":" + this.addZero(this.minute) + ":" + this.addZero(this.seconds);
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    getValue(item) {
      return item.actual_value.toFixed(2);
    },
    WebSocketConection() {
      var self = this;
      self.ConnectionDashboard = new WebSocket(
        `wss://lnuzjftr1a.execute-api.ap-south-1.amazonaws.com/chainhome?organization_id=${self.$store.getters.get_current_user_details.organization_id}&user_id=${self.$store.getters.get_current_user_details.user_id}`
      );
      self.ConnectionDashboard.onopen = function () {
        console.log("Connected!!!");
        self.connected = true;
        self.$emit("connected", self.connected);
      };
      self.ConnectionDashboard.onclose = function () {
        console.log("Disconnected On Close!!!");
        self.connected = false;
        self.$emit("connected", self.connected);
      };
      self.ConnectionDashboard.onerror = function () {
        self.connected = false;
        console.log("Disconnected On Error!!!");
        self.$emit("connected", self.connected);
      };
      self.ConnectionDashboard.onmessage = async function (event) {
        if (self.GetAllDashboardParameterList.length != 0) {
          console.log("Check The Data", JSON.parse(event.data));
          await self.formatData(JSON.parse(event.data));
        }
      };
    },
    changeBGColor(data) {
      var self = this;
      setTimeout(() => {
        data.bgcolor = "#F5F5F5";
        self.$forceUpdate();
      }, 1000);
    },
    changeColor(data) {
      var self = this;
      setTimeout(() => {
        data.color = "grey lighten-3";
        self.$forceUpdate();
      }, 1000);
    },
    async formatData(data) {
      switch (data.type) {
        case "HEARTBEAT":
          this.$emit("heartBeatColor", data.color);
          break;
        case "OPERATIONAL_PARAMETER":
          for (let i = 0; i < this.GetAllDashboardParameterList.length; i++) {
            if (
              this.GetAllDashboardParameterList[i].tag_id == data.tag_id &&
              this.GetAllDashboardParameterList[i].actual_value != data.actual_value &&
              typeof data.actual_value == "string"
            ) {
              this.GetAllDashboardParameterList[i].actual_value = data.actual_value;
              if (data.color_settings) {
                let color_found = false;
                let changed_color = "";
                for (let i = 0; i < data.color_settings.length; i++) {
                  if (
                    Number(data.actual_value) >= data.color_settings[i].start &&
                    Number(data.actual_value) <= data.color_settings[i].end
                  ) {
                    color_found = true;
                    changed_color = data.color_settings[i].color.toLowerCase();
                  }
                }
                if (color_found == true) {
                  this.GetAllDashboardParameterList[i].color = changed_color;
                } else {
                  this.GetAllDashboardParameterList[i].color = "orange";
                }
                this.changeColor(this.GetAllDashboardParameterList[i]);
              } else {
                this.GetAllDashboardParameterList[i].color = "orange";
                this.changeColor(this.GetAllDashboardParameterList[i]);
              }
              this.renderOPData = false;
              this.$nextTick(() => {
                this.renderOPData = true;
              });
            }
          }

          break;
        case "SUMMARY_PARAMETER":
          for (let i = 0; i < this.GetAllDashboardParameterList.length; i++) {
            if (
              this.GetAllDashboardParameterList[i].tag_id == data.tag_id &&
              this.GetAllDashboardParameterList[i].actual_value != data.actual_value &&
              typeof data.actual_value == "string"
            ) {
              this.GetAllDashboardParameterList[i].color = data.color;
              this.GetAllDashboardParameterList[i].bgcolor = data.color == "GREEN" ? "#e5ffe5" : "#ffe5e5";
              this.changeBGColor(this.GetAllDashboardParameterList[i]);
              this.renderData = false;
              this.$nextTick(() => {
                this.renderData = true;
              });
            }
          }
          if (data.step_name) {
            this.renderData = false;
            this.$nextTick(() => {
              this.stepObject[data.plant_name] = data;
              this.renderData = true;
            });
          }
          break;
        case "ALARM":
          this.GetAllInitialAlarmsList.unshift(data);
          break;
        case "DAILY_LOGS":
          if (data.type == "DAILY_LOGS") {
            if (data.parameter == "dmf_manual_backwash_value") {
              this.dailyLogs["dmf_manual_backwash_value"] = data["value"];
            }
            if (data.parameter == "uf_plant_inlet") {
              this.dailyLogs["uf_plant_inlet"] = data["value"];
            }
            if (data.data) {
              for (let i = 0; i < this.plant_types.length; i++) {
                if (data.data[this.plant_types[i]] != undefined) {
                  this.dailyLogs[this.plant_types[i]] = data.data[[this.plant_types[i]]];
                }
              }
              for (let key in data) {
                if (key != "data") {
                  this.dailyLogs[key] = data[key];
                }
              }
            } else {
              for (let i = 0; i < this.plant_types.length; i++) {
                if (data.parameter == `${this.plant_types[i].toLowerCase()}_recovery_percentage`) {
                  this.recovery_percentage[this.plant_types[i]] = "";
                  this.recovery_percentage[this.plant_types[i]] = data.value;
                }
              }
              for (let key in data) {
                for (let i = 0; i < this.plant_types.length; i++) {
                  if (data[key] == `${this.plant_types[i].toLowerCase()}_water_produced`) {
                    this.dailyLogs[`${this.plant_types[i].toLowerCase()}_water_produced`] = data.value;
                  }
                  if (data[key] == `${this.plant_types[i].toLowerCase()}_total_hours_of_operation`) {
                    this.dailyLogs[`${this.plant_types[i].toLowerCase()}_total_hours_of_operation`] = data.value;
                  }
                }
              }
            }
            this.renderData = false;
            this.$nextTick(() => {
              this.renderData = true;
            });
          }
          break;
      }
    },
  },
};
</script>
